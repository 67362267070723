import React, { useContext, useEffect } from 'react';
import produtoSemCapa from '../../assets/img/no_image_medium.jpg';
import cartContext from '../../Context/CartContext';
import { CardFiltro } from '../../DTOs/CardFiltro';
import { CarrinhoProdutoDTO } from '../../DTOs/produtoDTO';
import { useToast } from '../../hooks/toast';
import './styles.css';
import { formatarValores } from '../../helpers/valores';
import userContext from '../../Context/UserContext';

const CardProduto: React.FC<CardFiltro> = ({ Produtos, SetProdutos }: CardFiltro) => {
    const { addToast } = useToast();
    const currentlyCart = useContext(cartContext);
    const currentlyUser = useContext(userContext);
    const { Indapresentaprecocusto } = currentlyUser.user;


    function handleAdicionarCarrinho(produto: CarrinhoProdutoDTO) {
        const index = Produtos.findIndex(produtoCarrinho => produtoCarrinho.CodProduto === produto.CodProduto);

        // Se achou produto
        if (index > -1) {
            // Armazena o objeto na variável
            produto.Qtd = Produtos[index].Qtd
            produto.isDeleted = false;

        } else {
            produto.NroItem = 0;
        }

        currentlyCart.adicionaCarrinho(produto);

        addToast({
            type: 'success',
            title: 'Produto adicionado ao carrinho'
        });

    };

    function handleAlterarQuantidade(id: number, qtd: number) {

        const index = Produtos.findIndex(produtoCarrinho => produtoCarrinho.CodProduto === id);

        // Se achou produto
        if (index > -1) {
            const produto: CarrinhoProdutoDTO = Produtos[index];
            // Armazena o objeto na variável
            produto.Qtd = qtd;
        }

        currentlyCart.alteraQuantidade(id, qtd);
    };

    function Testa0(qtd: number | undefined) {

        if (qtd != undefined) {
            if (qtd == 0) {
                return "";
            }
        }

        return qtd;
    }

    return (
        <>
            {
                Produtos.map((produto) => (
                    <div className='card' key={produto.CodProduto}>
                        <div className='card-tags'>
                            {produto.Desconto > 0 ?
                                <div className="cardDesconto">
                                    <b>DESCONTO {produto.Desconto}%</b>
                                </div>
                                :
                                <div className="cardDesconto inativo">
                                </div>}

                            {produto.Inddestaque == 1 ?

                                <div className="cardMaisVendidos">
                                    <b>+ Vendidos</b>
                                </div> : null}

                            {produto.Indlancamento == 1 ?

                                <div className="cardLancamentos">
                                    <b>Lançamento</b>
                                </div> : null}

                            {produto.Indprevenda == 1 ?

                                <div className="cardPreVenda">
                                    <b>Pré-Venda</b>
                                </div> : null}
                        </div>
                        <div className="card-imagem">
                            {produto.Pathimagem === null ?
                                <div className="card-capa">
                                    <a href={`/produtos/${produto.CodProduto}/detalhes`}>{<img src={produtoSemCapa} alt={produto.Titulo} />}</a>
                                </div>
                                :
                                <div className="card-capa">
                                    <a href={`/produtos/${produto.CodProduto}/detalhes`}>{<img src={produto.Src} alt={produto.Titulo} />}</a>
                                </div>
                            }

                        </div>
                        <div className="card-infos">
                            <div className="card-titulo">
                                <a href={`/produtos/${produto.CodProduto}/detalhes`}>{produto.Titulo.length > 45 ? produto.Titulo.substring(0, 45) + "..." : produto.Titulo}</a>
                            </div>
                            {Indapresentaprecocusto && produto.Desconto != null && produto.Desconto > 0 ? (
                                <div className="dePorValoresCard">
                                    <div className="dePor">
                                        <p className="valorCard">
                                            De {produto.VlrFormatado}
                                        </p>

                                    </div>
                                    <p className="valorFinalCard">
                                        Por {
                                            (produto.VlrTotalFormatado = formatarValores(
                                                produto.Vlr -
                                                produto.Vlr * (produto.Desconto / 100)
                                            ))
                                        }
                                    </p>
                                </div>
                            ) : (
                                <div className="dePorValoresCard">
                                    <p className="valorFinalIndividualCard">
                                        <b>{produto.VlrFormatado}</b>
                                    </p>
                                </div>
                            )}
                            <div className="card-estoque">
                                {/* {produto.Qtdestoque > 0 ? <p>{produto.Dscsituacaoestoque}: <b> {produto.Qtdestoque}</b></p>
                                    : produto.Qtdestoque == -1 && produto.QuantidadeEmEstoque != null && produto.QuantidadeEmEstoque <= 0 ? <p>{produto.Dscsituacaoestoque}</p>
                                        : produto.Qtdestoque == -1 && produto.QuantidadeEmEstoque > 0 ? <p>{produto.Dscsituacaoestoque}: <b> {produto.QuantidadeEmEstoque}</b></p>
                                            : produto.Qtdestoque == null && produto.Dscsituacaoestoque == null && produto.QuantidadeEmEstoque == null ? <p> ESTOQUE: <b>0</b> </p> :
                                                produto.Dscsituacaoestoque != null ? produto.Dscsituacaoestoque : <p> ESTOQUE: <b>0</b> </p>} */}

{produto.Qtdestoque > 0 ? (
                                    <p>{produto.Dscsituacaoestoque}: <b>{produto.Qtdestoque}</b></p>
                                ) : produto.Qtdestoque == -1 && produto.QuantidadeEmEstoque != null ? (
                                  produto.QuantidadeEmEstoque <= 0 ? (
                                        <p>{produto.Dscsituacaoestoque}: {produto.Qtdestoque > -1 ? produto.Qtdestoque : ""}</p>
                                    ) : (
                                        <p>{produto.Dscsituacaoestoque}: <b>{produto.QuantidadeEmEstoque > -1 ? produto.QuantidadeEmEstoque : ""}</b></p>
                                    )
                                ) : produto.Qtdestoque == 0 && produto.Dscsituacaoestoque == null ? (
                                    <p>ESTOQUE: <b>0</b></p>
                                ) : produto.Dscsituacaoestoque != null ? (
                                    <p>{produto.Dscsituacaoestoque}: <b>{produto.Qtdestoque != null && produto.Qtdestoque > -1? produto.Qtdestoque : 0}</b></p>
                                ) : null}
                            </div>
                            <div className="card-botoes">
                                <div className="card-quantidade">
                                    <input
                                        type="number"
                                        className="contadorLivros"
                                        placeholder='1'
                                        min="1"
                                        max="100"
                                        value={Testa0(currentlyCart.cart.Produtos?.find((p) => {
                                            return p.CodProduto === produto.CodProduto;
                                        })?.Qtd)
                                        }
                                        onChange={(e) =>
                                            handleAlterarQuantidade(
                                                produto.CodProduto,
                                                Number(e.target.value)
                                            )
                                        }
                                        name="qtd"
                                    />
                                </div>
                                <div className="card-submit">
                                    {produto.Qtdestoque > 0 ? <button onClick={() => handleAdicionarCarrinho(produto)} className="adcCarrinho">Adicionar ao Carrinho</button>
                                        : produto.Qtdestoque == -1 && produto.QuantidadeEmEstoque <= 0 ? <button className="adcCarrinhoDisable">Adicionar ao Carrinho</button>
                                            : produto.Qtdestoque == -1 && produto.QuantidadeEmEstoque > 0 ? <button onClick={() => handleAdicionarCarrinho(produto)} className="adcCarrinho">Adicionar ao Carrinho</button>
                                                : <button onClick={() => handleAdicionarCarrinho(produto)} className="adcCarrinho">Adicionar ao Carrinho</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );
}

export default CardProduto;
