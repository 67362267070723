import * as React from 'react';
import { Container, TituloSecao } from './styles';
import Grid from '../../componentes/Grid';
import { useParams } from 'react-router-dom';
import userContext from '../../Context/UserContext';
import { useContext } from 'react';

const Categoria: React.FC = () => {
  const {id, name, indlancamento}: any = useParams();
  const currentlyUser = useContext(userContext);
  const {Codcliente, Codempresa} = currentlyUser.user;

  return (
    <Container>
      <TituloSecao>
       Categoria {id} - {decodeURIComponent(name)} 
      </TituloSecao>
        <Grid Categoria={id} Codcliente={Codcliente} Codempresacli={Codempresa}/>
    </Container>
  )
};

export default Categoria;
